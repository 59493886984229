*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.77) !important;
}

.flash
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    width: 100%;
    height: 100%;
    display: block;
    z-index: -1;
}


html,
body 
{
  margin: 0;
  height: 100%;
  overflow: visible; /*Make sure this is set to visible to facilitate scrolling animation*/
  -ms-overflow-style: none; /* Hide scrollbars on IE and Edge */
  scrollbar-width: none; /* Hide scrollbars in Firefox */
  overflow: -moz-scrollbars-none;
  /* scroll-behavior: smooth;  */
  scroll-snap-type: y mandatory;
  background-color: transparent;
  font-size: 1.007em !important;
}

html
{
  background-color: black !important;
}
/* Hide scrollbar for Chrome, Safari and Opera */
html::-webkit-scrollbar,
body::-webkit-scrollbar 
{
  display: none;
}

*,
*::before,
*::after 
{
  box-sizing: inherit;
  padding: 0px;
  margin: 0px;
}

.nav-container 
{
  width: 100%;
  height: auto;
  z-index: 1;
  position: fixed;
  padding-top: 7px;
}

nav 
{
  max-width: 1720px;
  margin: 10 auto;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  z-index: 1;
}

nav a 
{
  color: rgb(255, 255, 255);
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  transition: 0.4s all ease-in-out;
}

nav a:hover 
{
  opacity: 0.7;
}

a:link, a:visited, a:hover, a:active
{
  color: rgb(255, 255, 255) !important;
}

#scroll-container
{
  background: transparent !important;
  overflow: hidden;
  height: 100vh;;
}

.left-menu 
{
  grid-column: 1;
}

.left-menu a,
.fa-bars 
{
  padding: 10px 0;
  margin-left: 15px;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.5px;
}

.logo 
{
  grid-column: 2;
  font-size: 40px;
  padding: 1rem;
}

#nav-toggle,
.burger-menu 
{
  display: none;
}

.burger-menu 
{
  grid-column: 1;
  align-self: center;
  margin-left: 20px;
  z-index: 2;
}


.linkedIn
{
  position: absolute !important;
  right: 7px !important;
  top: 7px !important;
  font-size: x-large;
}

@media only screen and (max-width: 1025px) 
{
  .burger-menu 
  {
    display: inline-block;
    color: white !important;
    font-size: larger;
  }

  .left-menu 
  {
    display: none;
  }

  /* 
    Select all neighbours with the class of 'left-menu' 
     when the state of the element with nav-toggle id is checked
     the element with class is normally hidden, however when checked, 
     overwrite this by using display: grid. 
     Because no columns are specified, each element will take up the entire row. 
     Thus it will produce the look of a vertical list.
     Note that the parent <nav> element is a grid with columns 1fr auto 1fr. 
     Specifying grid-row 2 will create another row of 1fr auto 1fr.           
  */
  #nav-toggle:checked ~ .left-menu 
  {
    display: grid;
    grid-row: 2;
  }
}


.Home 
{
  display: grid;
  height: 100vh;
  background: transparent !important;
  grid-template-columns: 1fr auto 1fr;
  z-index: 1;
}

.homeContent 
{
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row: 3;
  color: rgb(255, 255, 255) !important;
  text-align: center;
  z-index: 10;
  align-self: center;
  font-size: x-large;
}

.expertise
{
  font-style: italic !important;
}

.learnMoreButton 
{
  color: white;
  grid-column: 3;
  grid-row: 3;
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  margin-right: 20px;
  margin-bottom: 20px;
  width: auto;
  font-size: large;
}

.learnMoreButton a 
{
  height: 20px;
  align-self: center;
  color: white;
  background-color: transparent;
  border-color: white;
}


a.scroll_link 
{
  white-space: nowrap;
}


.About {
  display: grid;
  height: 100vh;
  background: transparent;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 3;
}

.aboutContent 
{
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 3;
  grid-row-end: 4;
  color: rgb(255, 255, 255) !important;
  text-align: left;
  z-index: 1;
  align-self: baseline;
  font-size: larger;
  opacity: 1;
}

p {
  margin-top: .7em;
}



p {
  color: white;
}

a.scroll_link, #servicesLink, #contactLink {
  white-space: nowrap;
}



/* Media Queries */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) and (orientation : portrait)
{
  .aboutContent
  {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 4;
    grid-row-end: 4;
    color: rgb(255, 255, 255) !important;
    text-align: center;
    z-index: 1;
    align-self: center;
    font-size: medium;
    padding: 7px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) 
{
  .aboutContent
  {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 4;
    grid-row-end: 4;
    color: rgb(255, 255, 255) !important;
    text-align: center;
    z-index: 1;
    align-self: flex-end;
    font-size: medium;
    padding: 21px;
  } 
}

@media only screen and (min-width: 768px) and (orientation : portrait) 
{
  /* For mobile phones: */
  .aboutContent
  {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 4;
    color: rgb(255, 255, 255) !important;
    text-align: center;
    z-index: 1;
    align-self: center;
    font-size: larger;
    padding: 7px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) 
{
  .aboutContent
  {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 4;
    grid-row-end: 4;
    color: rgb(255, 255, 255) !important;
    text-align: center;
    z-index: 1;
    align-self: center;
    font-size: larger;
  } 
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) 
{
  .aboutContent
  {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 4;
    grid-row-end: 4;
    color: rgb(255, 255, 255) !important;
    text-align: center;
    z-index: 1;
    align-self: center;
    font-size: larger;
  }
}

/* Fade-in animation--->Note this class should be added to the body tag */
.animate-in 
{
  -webkit-animation: fadeIn .5s ease-in;
  animation: fadeIn .5s ease-in;
}

.animate-out 
{
  -webkit-transition: opacity .5s;
  transition: opacity .5s;
  opacity: 0;
}

@-webkit-keyframes fadeIn 
{
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeIn 
{
  from { opacity: 0; }
  to { opacity: 1; }
}

.myFooter 
{
  position: fixed;
  left: 0px;
  bottom: 1.2px !important;
  right: 0px;
  align-content: flex-end;
  z-index: 14;
  text-align: center;
  font-size: xx-small !important;
  padding-bottom: 1.2px;    
}

.footerDiv
{
    height: auto !important;
    /* background-color: none !important; */
    width: 100%;
    background-color: none !important;
    color: #fff;
}


@media only screen and (min-width: 300px) and (min-height: 320px) and (max-width: 1023px) and (orientation: landscape)
{ 

  .myFooter 
  {
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 1.2px !important;
    z-index: 14;
    text-align: center !important;
    font-size: xx-small;
    padding-bottom: 0px;    
  }

}

.Services 
{
  display: grid;
  height: 100vh;
  background: transparent;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 3;
}

.servicesContent 
{
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 3;
  grid-row-end: 4;
  color: rgb(255, 255, 255) !important;
  text-align: left;
  z-index: 1;
  align-self: baseline;
  font-size: larger;
  opacity: 1;
}

.loading
{
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3 !important;
    text-align: center;
    align-self: center;
}

p 
{
  margin-top: .7em;
}



p 
{
  color: white;
}

a.scroll_link, #servicesLink, #contactLink 
{
  white-space: nowrap;
}

#servicesTitle
{
  font-size: xx-large !important;
  color: #FFF !important;
}

/* Media Queries */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) 
{
  .servicesContent
  {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 2 !important;
    color: rgb(255, 255, 255) !important;
    text-align: center;
    z-index: 1;
    align-self: center;
    font-size: medium;
    padding: 7px;
  }

  .previous-button 
  {
    position: absolute !important;
    bottom: 7px !important; 
    left: 7px !important;
    font-size: small !important;  
  }

  .next-button 
  {
    position: absolute !important;
    bottom: 7px !important; 
    right: 7px !important;
    font-size: small !important;  
  }
  
}



/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) 
{
  .servicesContent
  {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 3 !important;
    color: rgb(255, 255, 255) !important;
    text-align: center;
    z-index: 1;
    align-self: center;
    font-size: medium;
    padding: 7px;
  }


  .previous-button 
  {
    position: absolute !important;
    bottom: 7px !important; 
    left: 7px !important;
    font-size: small !important;  
  }

  .next-button 
  {
    position: absolute !important;
    bottom: 7px !important; 
    right: 7px !important;
    font-size: small !important;  
  }

  
  

}


@media only screen and (min-width: 768px) and (orientation : portrait)
{
  .servicesContent
  {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 4;
    color: rgb(255, 255, 255) !important;
    text-align: center;
    z-index: 1;
    align-self: center;
    font-size: larger;
    padding: 7px;
  } 
  
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) 
{
  .servicesContent
  {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 4;
    grid-row-end: 4;
    color: rgb(255, 255, 255) !important;
    text-align: center;
    z-index: 1;
    align-self: center;
    font-size: larger !important;
  }
}



/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) 
{
  .servicesContent
  {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 4;
    color: rgb(255, 255, 255) !important;
    text-align: center;
    z-index: 1;
    align-self: center;
    font-size: larger !important;
  }
  .previous-button 
  {
    position: absolute !important;
    bottom: 7px !important; 
    left: 7px !important;
    font-size: small !important;  
  }

  .next-button 
  {
    position: absolute !important;
    bottom: 7px !important; 
    right: 7px !important;
    font-size: small !important;  
  }

}

/* Fade-in animation--->Note this class should be added to the body tag */
.animate-in {
  -webkit-animation: fadeIn .5s ease-in;
  animation: fadeIn .5s ease-in;
}

.animate-out {
  -webkit-transition: opacity .5s;
  transition: opacity .5s;
  opacity: 0;
}

@-webkit-keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

 .previous-button, .next-button
{
  position: absolute;
  z-index: 1;
  display: block;
  background-color: #000000;
  color: #FFF;
  font-size: x-large;
  border: none;
  margin: 12px;
  padding: 12px;
} 


.next-button:hover,
.previous-button:hover 
{
  cursor: pointer;
  color: #0eed6be5;
}


.cell_glow
{
    -webkit-box-shadow: 0px 0px 7px 7px rgba(144, 144, 144, .75);
    -moz-box-shadow: 0px 0px 7px 7px rgba(144, 144, 144, .75);
    box-shadow: 0px 0px 7px 7px rgba(144, 144, 144, .75);
}

.learnMoreBtn 
{
    background-color: black !important;
    color:white !important;
    padding: 12px !important;
    border: 1px solid white!important;
    cursor: pointer !important; 
}


.servicesModal
{
  z-index:12;
  display:none;
  padding-top:49px;
  position:fixed;
  left:0;
  top:0;
  width:100%;
  height:100%;
  background-color:rgb(0,0,0);
}

.hide
{
  display: none;
}


.servicesModal-content
{
  display: grid;
  margin:auto;
  position:relative;
  padding:0;
  outline:0;
  width:75%;
  height: 75%;
  overflow-y: auto;
  overflow-x: hidden !important;
    -ms-overflow-style: none; /* Hide scrollbars on IE and Edge */
  scrollbar-width: none; /* Hide scrollbars in Firefox */
  overflow: -moz-scrollbars-none;
  scroll-snap-type: y mandatory;
  background-color: transparent;
}

/**/
#servicesTitleModal
{
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
}

#serviceDesc
{
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 2;
}

#servicesLinkModal
{
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 4;
}



@media (max-width:600px)
{
  .servicesModal-content
  { 
    margin:0 10px;
    width:auto !important
  }
  .servicesModal
  {
    padding-top:30px
  }
}  

@media (max-width:768px)
{
  .servicesModal-content
  {
    width:100%;
  }
  .servicesModal
  {
    padding-top:49px
  }
}

@media (min-width:993px)
{
  .servicesModal-content
  {
    width:900px
  }
}

/* NOTE: The display:table is over-riding the grid layout. No need to specifiy grid columns/rows, however this has been done anyway.*/
.servicesModalContainer:after, .servicesModalContainer:before
{
  content:"";
  display:table;
  clear:both;
}

.servicesModalContainer
{
	padding: 0.01em 14px;
  background-color: #000000 !important;
}

.servicesModalBlack 
{
	color: #fff !important;
	background-color: #000 !important;
}

.servicesModalButton 
{
	border: none;
	display: inline-block;
	padding: 8px;
	vertical-align: middle;
	overflow: hidden;
	text-decoration: none;
	color: white;
	background-color: #000;
	text-align: center;
	cursor: pointer;
	white-space: nowrap; 
  font-size: large;
}

.servicesModalButton 
{
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}

.servicesModalButtonTopRight
{
	position: absolute;
	right: 0;
	top: 0
}


.servicesModalOpacity 
{
	animation: opac 1.2s
}

@keyframes opac 
{
	from 
  {
		opacity: 0
	}

	to 
  {
		opacity: 1
	}
}


.myFooter 
{
  position: fixed;
  left: 0px;
  bottom: 1.2px;
  right: 0px;
  align-content: flex-end;
  z-index: 14;
  text-align: center;
  font-size: xx-small !important;    
}

.footerDiv
{
    height: auto !important;
    background-color: none !important;
    width: 100%
}

.loading-background
{
  position: fixed;
    top: 0;
    left: 0;
    outline: none;
    width: 100%;
    height: 100%;
    display: grid;
    z-index: 7;
    background-color: #000000;
    transform: scale(1.0); 
    transform-origin: top left;
    transition: transform 0.5s;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: 3;
}

.loading-background.ended
{
    display: none;
    transform: scaleX(0);
    transform-origin: 100% 0; 
    transition: transform 1.5s ease-in-out; 
}

.loading-bar
{
    position: absolute;
    top: 50%;
    width: 100%;
    height: 2px;
    background: #ffffff;
    transform: scale(0.0); 
    transform-origin: top left;
    transition: transform 0.5s;
}

.loading-bar.cell_glow
{
  -webkit-box-shadow: 0px 0px 7px 7px rgba(49, 14, 129, 0.75);
    -moz-box-shadow: 0px 0px 7px 7px rgba(49, 14, 129, 0.75);
    box-shadow: 0px 0px 7px 7px rgba(49, 14, 129, 0.75);
}

.loading-bar.ended
{
    transform: scaleX(0);
    transform-origin: 100% 0; 
    transition: transform 1.5s ease-in-out;
}

form
{
  display: grid;
  margin: 0 auto 0 auto; 
  background: transparent !important;
  grid-template-columns: 1fr 4fr 1fr;
  grid-gap: 14px;
  max-width: 777px; 
  align-self: center;
}

h3
{
  color: #fff;
  grid-column: 1/4;
  grid-row: 1/2;
  justify-self: center;
  align-self: flex-end;
  vertical-align: bottom;
}

form label
{
  color: #fff;
  grid-column: 1/2;
  justify-self: end;
  align-self: center;
  padding: 0.5em 0.5em 0.5em 0;
}

form input
{
  border: 1px solid #fff;
  grid-column: 2/3;
  min-height: max-content;
  height: 2em;
}

#message
{
  border: 1px solid #fff;
  height: 7.77em;
  resize: none;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  background: transparent !important;
  color: #fff !important;
}

#submit
{
  border: 1px solid #fff;
  color: #fff;
  grid-column: 1/4;
  justify-self: center;
  padding: 14px;
  border-radius: 7px;
  margin-top: 7px;
}

#contactContent
{
  display: flex;
  width: 100vw; 
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.whiteCaret
{
    caret-color: #fff !important;
}




@media only screen and (min-width: 300px) and (max-height: 770px) and (orientation: portrait)
{
  #contactContent
  {
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: flex-end !important;
    justify-content: center;
    padding-top: 1.2em;
  }

  form 
  {
    display: grid;
    margin: 0 auto 0 auto; 
    background: transparent !important;
    grid-template-columns: 1fr 4fr 1fr; 
    grid-gap: 7px;
    width: 100% !important;  
    align-self: center;
    padding-left: 0.77em;  
  }

  form input 
  {
    font-size: .77em; 
  }

  
  
}

/* Handle Galaxy fold single screen */
@media only screen and (max-width: 299px) and (orientation: portrait)
{
   form 
   {
     font-size: x-small !important;
     padding-left: 14px;
   }
   form input, textarea 
   {
     font-size: x-small !important;
   }
}

@media only screen and (max-height: 280px) and (orientation: landscape)
{
   .myFooter
   {
     text-align: right !important;
   }
}


@media only screen and (min-width: 300px) and (min-height: 670px) and (orientation: portrait)
{

  form 
  {
    display: grid;
    margin: 0 auto 0 auto; 
    background: transparent !important;
    grid-template-columns: 1fr 4fr 1fr; 
    /* grid-template-columns: 12px auto 12px; */
    grid-gap: 3px;
    width: 77% !important;  
    align-self: center;  
  }

  h3
  {
    color: #fff;
    grid-column: 1/4;
    grid-row: 1/2;
    justify-self: center;
    align-self: flex-end;
    vertical-align: bottom;
  }

  form label
  {
    color: #fff;
    grid-column: 1/3;
    justify-self: start;
    align-self: end;
    padding: 0.5em 0.0em 0.0em 0.0em;
  }

  form input
  {
    border: 1px solid #fff;
    grid-column: 1/4;
    margin-bottom: 21px;
  }

  #message
  {
    border: 1px solid #fff;
    height: 7.77em;
    resize: none;
    grid-column: 1/4;
  }

  #submit
  {
    border: 1px solid #fff;
    color: #fff;
    grid-column: 1/4;
    justify-self: center;
    padding: 14px;
    border-radius: 7px;
    margin-top: 12px;
  }

}

@media only screen and (min-width: 300px) and (max-height: 320px) and (orientation: landscape)
{

  form 
  {
    display: grid;
    margin: 0 auto 0 auto; 
    background: transparent !important;
    grid-template-columns: 1fr 4fr 1fr; 
    /* grid-template-columns: 12px auto 12px; */
    grid-gap: 3px;
    width: 77% !important;  
    align-self: center;  
    font-size: small;
  }

  h3
  {
    margin-top: .12em;
  }


  /* 
  form label
  {
    color: #fff;
    grid-column: 1/3;
    justify-self: start;
    align-self: end;
    padding: 0.5em 0.0em 0.0em 0.0em;
  }

  form input
  {
    border: 1px solid #fff;
    grid-column: 1/4;
    margin-bottom: 21px;
  }

  #message
  {
    border: 1px solid #fff;
    height: 7.77em;
    resize: none;
    grid-column: 1/4;
  }
  */

  /* #contactContent
  {
    background-color: #fff;
  } */

  #submit
  {
    border: 1px solid #fff;
    color: #fff;
    justify-self: center;
    padding: 3px;
    border-radius: 7px;
    margin-top: 7px;
  } 

  /* .myFooter 
  {
    position: fixed;
    left: 0px;
    bottom: 1.2px;
    right: 7px !important;
    align-content: flex-end;
    z-index: 14;
    text-align: right !important;
    font-size: xx-small !important;
    padding-bottom: 7px;    
  } */

}

@media only screen and (min-width: 300px) and (min-height: 320px) and (max-width: 1023px) and (orientation: landscape)
{

  form 
  {
    display: grid;
    margin: 0 auto 0 auto; 
    background: transparent !important;
    grid-template-columns: 1fr 4fr 1fr; 
    grid-gap: 3px;
    width: 77% !important;  
    align-self: center;  
    font-size: small;
    padding-left: 0.77em;
  }

  h3
  {
    margin-top: .12em;
  }

  #submit
  {
    border: 1px solid #fff;
    color: #fff;
    justify-self: center;
    padding: 3px;
    border-radius: 7px;
    margin-top: 7px;
  } 

  .myFooter 
  {
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 1.2px !important;
    z-index: 14;
    text-align: center !important;
    font-size: xx-small;
    padding-bottom: 0px;    
  }

}


 
/******************************Style to override auto-complete**************************************/
input, select 
{
    color: #fff !important;
    -webkit-text-fill-color: #fff !important;
    -webkit-background-clip: text !important;
    background-clip:  text !important;
}


/* Modern Browsers */
textarea::placeholder {
    font-size: 1em;
    color: #fff;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

/* WebKit, Edge */
textarea::-webkit-input-placeholder {
    font-size: 1em;
    color: #fff;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

/* Firefox 4-18 */
textarea:-moz-placeholder {
    font-size: 1em;
    color: #fff;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

/* Firefox 19+ */
textarea::-moz-placeholder {
    font-size: 1em;
    color: #fff;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

/* IE 10-11 */
textarea:-ms-input-placeholder {
    font-size: 1em;
    color: #fff;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

/* Edge */
textarea::-ms-input-placeholder {
    font-size: 1em;
    color: #fff;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}


/*Footer*/
.myFooter 
{
  position: fixed;
  left: 0px;
  bottom: 1.2px !important;
  right: 0px;
  align-content: flex-end;
  z-index: 14;
  text-align: center;
  font-size: xx-small !important;
  padding-bottom: 1.2px;    
}

.footerDiv
{
    height: auto !important;
    /* background-color: none !important; */
    width: 100%;
    background-color: none !important;
}


.ContactSuccess 
{
  display: grid;
  height: 100vh;
  background: transparent;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 1fr auto 1fr;
}

.contactSuccessContent
{
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 3;
  grid-row-end: 3;
  color: rgb(255, 255, 255) !important;
  z-index: 1;
  align-self: baseline;
  text-align: center;
  font-size: larger;
  padding-bottom: 49px;
  justify-items: center;
}

#contactSuccessBtn 
{
  border: 1px solid #fff;
  color: #fff;
  padding: 14px;
  border-radius: 7px;
  margin-top: 7px;
}

.returnHomeDiv
{
  text-align: center;
}

.contactSuccessContent p 
{
  color: #fff;
  margin-top: .7em;
}

